import * as React from "react"
import cn from "clsx"
import dynamic from "next/dynamic"

import { useI18n } from "next-localization"
import { CommentButton } from "../comment-button"

import { useGetFavoritePhotos } from "@app/features/masonry/hooks"
import { useSetAllPhotos } from "@app/features/photo-carousel/store/hooks"

const Masonry = dynamic(() => import("@app/features/masonry/components").then((mod) => mod.Masonry), {
  ssr: false,
})

import { useGallery } from "@app/contexts"

import { Container } from "@app/ui/container"
import { CompleteSelection } from "../complete-selection"
import { Empty } from "@app/features/favorites/components"
import { PhotoCarousel } from "@app/features/photo-carousel/components"
import { ProductsSection } from "@app/features/products/components/products-section"
import { ProductsContainer } from "@app/features/products/components/products-container"

import styles from "./favorites.module.scss"

const Favorites = ({ favoritesListId, isDownloadEnabled, completedSelection }) => {
  const [isContentLoading, setIsContentLoading] = React.useState(true)
  const $favoritePhotos = useGetFavoritePhotos()
  const $setAllPhotos = useSetAllPhotos()

  const { gallery } = useGallery()

  const { t } = useI18n()

  const isShowProductSection = gallery?.photoCount >= 20 && gallery.isPrintableProductsEnabled

  React.useEffect(() => {
    $setAllPhotos($favoritePhotos)
    setIsContentLoading(false)
  }, [$favoritePhotos, $setAllPhotos])

  const isPhotosExist = !!$favoritePhotos?.length

  return (
    <React.Fragment>
      <div
        className={cn(styles["root"], {
          [styles["grid"]]: isPhotosExist,
          [styles["empty"]]: !isPhotosExist,
        })}
      >
        {!isContentLoading && isPhotosExist && (
          <Container isFullWidth={true} className={styles["container"]}>
            <Masonry
              mediaFiles={$favoritePhotos}
              type="favorites"
              favoritesListId={favoritesListId}
              isDownloadEnabled={isDownloadEnabled}
            ></Masonry>
            {completedSelection && <CommentButton />}
            {!completedSelection && <CompleteSelection countPhotos={$favoritePhotos?.length} />}
          </Container>
        )}
        {!isContentLoading && (
          <PhotoCarousel
            type="favorites"
            favoritesListId={favoritesListId}
            isDownloadEnabled={isDownloadEnabled}
          ></PhotoCarousel>
        )}
        {!isContentLoading && !isPhotosExist && <Empty></Empty>}
      </div>
      {isShowProductSection && (
        <ProductsContainer>
          <ProductsSection
            title={t("productsModal.base.title")}
            description={t("productsModal.base.description")}
            onlyAvailable={false}
            position={"footer"}
          />
        </ProductsContainer>
      )}
    </React.Fragment>
  )
}

export { Favorites }
